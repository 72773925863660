import React, { useState } from 'react'
import { sendMail, useSubmitForm } from '../../functions/sendMail';
import forms from '../../data/forms.json'
import { BasicAlert } from '../basic/BasicAlert';

const FormContact = ({ settings, contentCollection }) => {

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const { submitForm, loading, error, success } = useSubmitForm();


    const formContent = forms[settings.formId]

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const validateForm = () => {
        const newErrors = {}
        formContent.contentCollection.forEach((field) => {
            if (field.settings.required && !formData[field.settings.name]) {
                newErrors[field.settings.name] = 'This field is required';
            }
            if (field.settings.type === 'email' && formData[field.settings.name] && !formData[field.settings.name].match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                newErrors[field.settings.name] = 'Please enter a valid email address';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Call the custom hook function to submit the form
        submitForm(formData);
    };
    return (
        <div className={`
        col${formContent.settings && formContent.settings.responsive ? `-lg` : ''}${formContent.settings && formContent.settings.colSize ? `-${formContent.settings.colSize}` : ''}
        ${formContent.settings && formContent.settings.textAlign && formContent.settings.textAlign} 
        ${formContent.settings && formContent.settings.classes ? formContent.settings.classes : ''}
        ${formContent.settings && formContent.settings.backgroundColor ? `bg-${formContent.settings.backgroundColor}` : ''}
        `}>
            {loading &&
                <div className='py-4'>
                    <BasicAlert color='info' message='Even geduld...' />
                </div>}
                {error &&
                <div className='py-4'>
                    <BasicAlert color='danger' message='Er is iets misgegaan. Probeer telefonisch contact op te nemen of the mailen naar info@wood-projects.be.' />
                </div>}
            {success ?
                <div className='py-4'>
                    <BasicAlert color='success' message='Bedankt! We nemen zo snel mogelijk contact met je op' />
                </div> :
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={`row ${formContent.settings && formContent.settings.blockAlign ? `justify-content-${formContent.settings.blockAlign}` : ''} `}>
                        {formContent && formContent.contentCollection.map((field, index) => {
                            switch (field.settings.type) {
                                case 'text':
                                case 'email':
                                    return (
                                        <div key={index} className={`mb-3 col-lg-${field.settings.size}`}>
                                            <label htmlFor={`field-${field.id}`} className="form-label">{field.settings.label}{field.settings.required && '*'}</label>
                                            <input type={field.settings.type} className="form-control" id={`field-${field.id}`} name={field.settings.name} placeholder={field.settings.placeholder} onChange={handleChange} required={field.settings.required} />
                                            {errors[field.settings.name] && errors[field.settings.name]}
                                        </div>
                                    )
                                case 'textarea':
                                    return (
                                        <div key={index} className={`mb-3 col-lg-${field.settings.size}`}>

                                            <label htmlFor={`field-${field.id}`} className="form-label">{field.settings.label}{field.settings.required && '*'}</label>
                                            <textarea className="form-control" id={`field-${field.id}`} name={field.settings.name} placeholder={field.settings.placeholder} onChange={handleChange} required={field.settings.required} rows="3"></textarea>

                                            {errors[field.settings.name] && errors[field.settings.name]}
                                        </div>
                                    )
                                default:
                                    return (<p>Field not found</p>);
                            }
                        })}
                    </div>
                    <div className={`row ${formContent.settings && formContent.settings.blockAlign ? `justify-content-${formContent.settings.blockAlign}` : ''} `}>
                        <div className='col-auto'>
                            <button type="submit" className={`btn btn-${formContent.settings && formContent.settings.buttonColor ? formContent.settings.buttonColor : 'primary'} w-100`}> {formContent.settings && formContent.settings.buttonLabel}</button>
                        </div>
                    </div>
                </form>}
        </div>
    )
}

export default FormContact